import Grid from "@mui/material/Unstable_Grid2";
import Typography from "@mui/material/Typography";
import React from "react";

const Legend = () => {
    return (
        <div className="legend">
            <Grid id={"label-feedback"} container justifyContent="center" sx={{backgroundColor: "#eeeeee"}}>
                <Grid container xs={12} sm={12}>
                    <Grid xs={3}>
                        <Typography variant="body1" sx={{color: "#808080"}}>
                            <span style={{backgroundColor: "#fbc8b8", width: "10px", height: "10px", display: "inline-block", marginRight: "5px"}}></span>
                            Obsazeno
                        </Typography>
                    </Grid>
                    <Grid xs={2} >
                        <Typography variant="body1" sx={{color: "#808080"}}>
                            <span style={{backgroundColor: "#0498f1", width: "10px", height: "10px", display: "inline-block", marginRight: "5px"}}></span>
                            Volno
                        </Typography>
                    </Grid>
                    <Grid xs={2} >
                        <Typography variant="body1" sx={{color: "#808080"}}>
                            <span style={{backgroundColor: "#ffffff", width: "10px", height: "10px", display: "inline-block", marginRight: "5px"}}></span>
                            Zavřeno
                        </Typography>
                    </Grid>

                    <Grid xs={4}>
                        <Typography variant="body1" sx={{color: "#808080"}}>
                            <span style={{
                                backgroundColor: "#ffffff",
                                width: "10px",
                                height: "25px",
                                display: "inline-block",
                                marginRight: "3px",
                                paddingLeft: "3px",
                                paddingTop: "0px",
                            }}>
                                ↕
                            </span>
                            Plavání na šířku bazénu
                        </Typography>
                    </Grid>
                </Grid>
                <Grid xs={12} sm={12}>
                    <Typography variant="body1" sx={{color: "#808080"}}>
                        <span style={{
                            backgroundColor: "#00ee00",
                            width: "10px",
                            height: "3px",
                            display: "inline-block",
                            marginRight: "0px",
                            marginBottom: "4px"
                        }}></span>
                        <span style={{
                            backgroundColor: "#eeee00",
                            width: "10px",
                            height: "3px",
                            display: "inline-block",
                            marginRight: "0px",
                            marginBottom: "4px"
                        }}></span>
                        <span style={{
                            backgroundColor: "#ee0000",
                            width: "10px",
                            height: "3px",
                            display: "inline-block",
                            marginRight: "10px",
                            marginBottom: "4px"
                        }}></span>
                        Vytíženost podle Google Maps
                    </Typography>
                </Grid>
            </Grid>
        </div>
    )
}
export default Legend;