import * as React from 'react';
import {useCallback, useEffect, useRef} from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {Lane, OneDayScheduleProps} from "./Schedule";

function createRowsFromLanes(
    name: string,
    indexTime: number,
    lanes: Lane[] | undefined
): (string)[] {
    let slots: (string)[] = lanes?.map((lane) => lane.slots?.[indexTime]) || new Array(0);
    return [name, ...slots];
}

const lanePadding: number = 5
const occupiedColor: string = "#fbc8b8"
const accessibleColor: string = "#7ecffc"
const closeColor: string = "#ffffff"
const lineThickness = 3;

const DayScheduleTable: React.FC<OneDayScheduleProps> = ({day, onValueFromChild, rowToScrollId, hours}) => {
    const timeRows: string[][] | undefined = hours?.map((hour: string, index: number) => (
            createRowsFromLanes(hour, index, day?.lanes)
        )
    )
    const tableContainerRef = useRef<HTMLDivElement>(null);

    const handleScroll = useCallback(() => {
        const table = tableContainerRef.current;
        if (table) {
            const scrollTop = table.scrollTop + lineThickness;
            const rows = table.getElementsByTagName('tr');
            for (let i = 0; i < rows.length; i++) {
                const row = rows[i];
                if (
                    (row.offsetTop <= scrollTop && row.offsetTop + row.clientHeight > scrollTop)
                    && row.id.toString().trim().length !== 0) {
                    if (onValueFromChild) {
                        onValueFromChild(row.id);
                    }
                    break;
                }
            }
        }
    }, [onValueFromChild]);

    useEffect(() => {
        const div = tableContainerRef.current;
        div?.addEventListener('scroll', () => handleScroll());

        const rowId = rowToScrollId ? rowToScrollId : `row-${new Date().getHours() - 1}30`
        scrollToRow(rowId)

        return () => {
            div?.removeEventListener("scroll", () => handleScroll());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function scrollToRow(rowIndex: string) {
        if (rowIndex.length === 0) {
            return
        }
        const tableContainer: HTMLDivElement | null = tableContainerRef.current;
        if (tableContainer) {
            const row = tableContainer.querySelector(`#${rowIndex}`);
            if (row instanceof HTMLElement) {
                //TODO use behavior: 'smooth' only when first loading the page
                // const scrollType = firstLoad ? 'smooth' : 'auto'
                const scrollType = 'auto'
                row.scrollIntoView({behavior: scrollType, block: 'start'});
            }
        }
    }

    return (
        <TableContainer component={Paper} sx={{maxHeight: 500}} ref={tableContainerRef}>
            <Table stickyHeader={true} sx={{minWidth: '100%'}} size="small" aria-label="Swimming pool schedule">
                <TableHead>
                    <TableRow sx={{'td, th': {border: 1, borderColor: 'lightgray'}}} id={'line-numbers'}>
                        <TableCell key={0} align="center" style={{paddingInline: lanePadding, position: "sticky", left: 0}}></TableCell>
                        {day?.lanes?.map((lane, index) => (
                            <TableCell key={index + 1} align="center" style={{paddingInline: lanePadding}}>{lane.number}</TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {timeRows?.map((timeRow: string[], index) => (
                        <TableRow
                            key={timeRow[0]}
                            id={`row-${timeRow[0].replace(":", "")}`}
                            // sx={{'&:last-child td, &:last-child th': {border: 1}}}
                            sx={{
                                th: {
                                    border: 1, borderColor: 'lightgray',
                                    borderTopColor: timeRow[0].endsWith("00") ? '#a4a2a2' : '#D3D3D3',
                                    borderBottomColor: timeRow[0].endsWith("45") ? '#a4a2a2' : '#D3D3D3',
                                    background: timeRow[0].startsWith(new Date().getHours().toFixed()) ? '#D3D3D3' : '#FFFFFF'
                                },
                                td: {
                                    border: 1,
                                    borderColor: 'lightgray',
                                    borderTopColor: timeRow[0].endsWith("00") ? '#a4a2a2' : '#D3D3D3',
                                    borderBottomColor: timeRow[0].endsWith("45") ? '#a4a2a2' : '#D3D3D3',
                                }
                            }}
                            // sx={ {border: 4}}
                            // style={{border: 1}}
                        >
                            {(timeRow[0].endsWith("00")) &&
                                <TableCell component="th" scope="row" align="center" key={0} rowSpan={4}
                                           style={{paddingInline: lanePadding, position: "sticky", left: 0}}>
                                    {timeRow[0]}
                                </TableCell>
                            }

                            {timeRow.slice(1).map((slot: string, index: number) => (
                                <TableCell align="center" key={index} style={{
                                    paddingInline: lanePadding,
                                    background: slot === "O" ? occupiedColor : (slot === "C" ? closeColor : accessibleColor),
                                    color: slot === "O" ? occupiedColor : (slot === "C" ? closeColor : accessibleColor),
                                    fontSize: 9,
                                    padding: 2
                                }}>{slot === "A" ? "a" : "×"}</TableCell>
                            ))}

                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default DayScheduleTable;