import React, {useState} from 'react';
import {Alert, AlertColor, Button, Link, TextField, Tooltip} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Typography from "@mui/material/Typography";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

interface UserFeedbackProps {
    poolId: string | undefined;
}

const UserFeedback: React.FC<UserFeedbackProps> = ({poolId}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [buttonDisabled, setButtonDisabled] = useState(true);
    const [buttonText, setButtonText] = useState("Odeslat");
    const [inputValue, setInputValue] = useState('');
    const [sendText, setSendText] = useState('');
    const [infoMessage, setInfoMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [severityMessage, setSeverityMessage] = useState<AlertColor | undefined>("success");

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(event.target.value);
        setInfoMessage("")
        // if (event.target.value.trim().length < 1 || event.target.value.trim() === sendText.trim()) {
        if (event.target.value.trim().length < 1 || event.target.value.trim() === sendText.trim()) {
            setButtonDisabled(true)
        } else {
            setButtonDisabled(false)
            if (sendText.trim().length > 0) {
                setButtonText("Odeslat znovu")
            }
        }
    };

    const handleClick = async () => {
        setIsLoading(true);
        setButtonText("Odesílám...")

        const myHeaders = new Headers();
        myHeaders.append('Content-Type', 'application/json');

        const raw = JSON.stringify({
            poolId: poolId,
            text: inputValue,
            userData: 'any user data',
        });

        const requestOptions: RequestInit = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow',
        };

        try {
            const response = await fetch(
                'https://starfish-app-tla65.ondigitalocean.app/pools/feedbacks',
                requestOptions
            );
            if (response.status.toString().startsWith("2")) {
                setSendText(inputValue)
                setButtonDisabled(true)
                setButtonText("Odesláno")
                setInfoMessage("Zpráva odeslána")
                setSeverityMessage("success")
                setErrorMessage("")
            } else {
                setButtonText("Neodesláno, zkusit znovu")
                setInfoMessage("Zpráva neodeslána")
                setSeverityMessage("warning")
                setErrorMessage("status: " + response.status)
            }
        } catch (error) {
            console.log('error', error);
            setButtonText("Neodesláno, zkusit znovu")
            setInfoMessage("Zpráva neodeslána")
            setSeverityMessage("error")
            setErrorMessage(error as string)
        }

        setIsLoading(false);
    };

    return (
        <div>
            <Grid id={"label-feedback"} container justifyContent="center" sx={{backgroundColor: "#eeeeee"}}>
                <Grid xs={12} md={12} paddingTop={5}>
                    <Typography variant="h6" align="center" color={"#999999"}>
                        Zpětná vazba k webu.
                    </Typography>
                    <Typography variant="h6" align="center" color={"#999999"}>
                        Návrhy na přídání dalších bazénů.
                    </Typography>
                </Grid>
            </Grid>

            <Grid id={"feedback-input"} container justifyContent="center">
                <Grid xs={1} sm={2} md={3} lg={4} xl={4}></Grid>
                <Grid xs={10} sm={8} md={6} lg={4} xl={3} pb={1}>
                    <TextField
                        id="form-feedback"
                        multiline={true}
                        rows={4}
                        fullWidth={true}
                        sx={{backgroundColor: "#ffffff"}}
                        value={inputValue}
                        onChange={handleInputChange}
                    />
                </Grid>
                <Grid xs={1} sm={2} md={3} lg={4} xl={4}></Grid>
            </Grid>

            <Grid id={"button-feedback"} container justifyContent="center">
                <Grid xs={1} sm={2} md={3} lg={4} xl={4}></Grid>
                <Grid xs={10} sm={8} md={6} lg={4} xl={3}>
                    <Tooltip title={errorMessage}>
                        <Button onClick={handleClick} disabled={isLoading || buttonDisabled} variant="contained" sx={{backgroundColor: "#0498f1"}}>
                            {buttonText}
                        </Button>
                    </Tooltip>

                </Grid>
                <Grid xs={1} sm={2} md={3} lg={4} xl={4}></Grid>
            </Grid>

            <Grid id={"button-feedback"} container justifyContent="center">
                <Grid xs={1} sm={2} md={3} lg={4} xl={4}></Grid>
                <Grid xs={10} sm={8} md={6} lg={4} xl={3} paddingBottom={10} paddingTop={2}>
                    {infoMessage.length > 0 &&
                        <Alert onClose={() => {
                            setInfoMessage("")
                        }} severity={severityMessage} title={"dddd"}>
                            {infoMessage}</Alert>
                    }
                </Grid>
                <Grid xs={1} sm={2} md={3} lg={4} xl={4}></Grid>
            </Grid>

            <Grid container alignContent={"center"}>
                <Grid xs={3} md={5}></Grid>
                <Grid xs={4} md={1}>
                    {!matches && <Link href="https://www.buymeacoffee.com/jaroslavholan" target="_blank" rel="noopener noreferrer">
                        <img src="https://cdn.buymeacoffee.com/buttons/v2/default-yellow.png"
                             alt="Buy Me A Coffee"
                             style={{height: "45px", paddingBottom: "10px"}}
                        />
                    </Link> }
                </Grid>
                <Grid xs={4} md={4}></Grid>
            </Grid>
        </div>
    );

};

export default UserFeedback;
