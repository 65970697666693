import React from 'react';
import './App.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import ScheduleTable from "./pages/ScheduleTable";

function App() {

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<ScheduleTable/>}>
                    <Route path="/pools" element={<ScheduleTable/>}/>
                    <Route path="/pools/:poolId" element={<ScheduleTable/>}/>
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

export default App;
