import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import EmailIcon from '@mui/icons-material/Email';
import React from "react";
import {BottomNavigation, BottomNavigationAction, Link} from "@mui/material";
import Typography from "@mui/material/Typography";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const Footer = () => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));

    return (
        <BottomNavigation sx={{backgroundColor: "#0498f1"}}>
            {/*<Typography variant="body2" color="textSecondary" align="center" sx={{bottom: 0, paddingTop: 2}}>*/}
            {/*    Designed by&nbsp;*/}
            {/*    <Link href="http://www.jaroslavholan.com" target="_blank" underline="hover" color="inherit">*/}
            {/*        Holaň*/}
            {/*    </Link>*/}
            {/*</Typography>*/}
            {matches && <Link href="https://www.buymeacoffee.com/jaroslavholan" target="_blank" rel="noopener noreferrer" >
                <img src="https://cdn.buymeacoffee.com/buttons/v2/default-yellow.png"
                     alt="Buy Me A Coffee"
                     style={{height: "45px", marginTop: "5px", marginLeft: "5px"}}
                />
            </Link> }
            <BottomNavigationAction label="Twitter" icon={<TwitterIcon/>} href="https://twitter.com/JaroslavHolan" target="_blank"/>
            <BottomNavigationAction label="LinkedIn" icon={<LinkedInIcon/>} href="https://www.linkedin.com/in/jaroslavholan/" target="_blank"/>
            <BottomNavigationAction label="Email" icon={<EmailIcon/>} href="mailto:hello@swimslot.com" target="_blank"/>
            <Typography variant="body2" color="textSecondary" align="center" sx={{bottom: 0, paddingTop: 2}}>
                Favicon by&nbsp;
                <Link href="https://www.flaticon.com/authors/freepik" target="_blank" underline="hover" color="inherit">
                    Freepik
                </Link>
            </Typography>
        </BottomNavigation>
    );
};

export default Footer;
