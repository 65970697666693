import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {HourlyOccupancy, Lane, OneWeekScheduleProps, PopularTimes} from "./Schedule";
import {TableFooter, Tooltip} from "@mui/material";
import Legend from "./componets/Legend";
// import { styled } from '@mui/material/styles';
// import IconButton from "@mui/material/IconButton";
// import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
// import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';


const occupiedColor: string = "#fbc8b8"
const accessibleColor: string = "#7ecffc"
const closeColor: string = "#ffffff"
const lanePadding: number = 0
const laneNumberPadding: number = 5

const daysOfWeek: { [key: string]: string } = {
    'MONDAY': 'Pondělí',
    'TUESDAY': 'Úterý',
    'WEDNESDAY': 'Středa',
    'THURSDAY': 'Čtvrtek',
    'FRIDAY': 'Pátek',
    'SATURDAY': 'Sobota',
    'SUNDAY': 'Neděle',
    '': '    ',
};

// const StyledTableCell = styled(TableCell)(({ theme }) => ({
//     head: {
//         backgroundColor: theme.palette.common.black,
//         color: theme.palette.common.white,
//     },
//     body: {
//         fontSize: 14,
//         '&:hover': {
//             backgroundColor: '#f5f5f5',
//             cursor: 'pointer'
//         },
//     },
// }));

const getPopularityBackgroundColor = (popularityTimes: PopularTimes | undefined, day: string | undefined, hour: string) => {
    let dayKey: string = ""
    if (day !== undefined) {
        dayKey = day.substring(0, 2).toLowerCase()
    }

    const hourKey = hour.split(":")[0]

    if (popularityTimes !== undefined && popularityTimes.popularTimesHistogram !== undefined) {
        const hourlyOccupancy: HourlyOccupancy[] = popularityTimes?.popularTimesHistogram[dayKey as keyof typeof popularityTimes.popularTimesHistogram]
        const occupancyPercent: HourlyOccupancy[] = hourlyOccupancy.filter((hourlyOccupancy) => {
            return hourlyOccupancy.hour === parseInt(hourKey)
        })
        const p: number = occupancyPercent[0].occupancyPercent
        return getColor6(p);
    }
    return getColor6(0);
}

const getPopularityPercent = (popularityTimes: PopularTimes | undefined, day: string | undefined, hour: string) => {
    let dayKey: string = ""
    if (day !== undefined) {
        dayKey = day.substring(0, 2).toLowerCase()
    }

    const hourKey = hour.split(":")[0]

    if (popularityTimes !== undefined && popularityTimes.popularTimesHistogram !== undefined) {
        const hourlyOccupancy: HourlyOccupancy[] = popularityTimes?.popularTimesHistogram[dayKey as keyof typeof popularityTimes.popularTimesHistogram]
        const occupancyPercent: HourlyOccupancy[] = hourlyOccupancy.filter((hourlyOccupancy) => {
            return hourlyOccupancy.hour === parseInt(hourKey)
        })
        return occupancyPercent[0].occupancyPercent;
    }
    return 0;
}

// function getColor(percent: number): string {
//     const red = percent < 50 ? Math.round(percent * 5.1) : Math.round(255 - (percent - 50) * 5.1);
//     const green = percent < 50 ? Math.round(percent * 5.1) : Math.round(255 - (percent - 50) * 5.1);
//     const blue = percent < 50 ? Math.round(255 - percent * 5.1) : Math.round((percent - 50) * 5.1);
//     return `#${red.toString(16).padStart(2, '0')}${green.toString(16).padStart(2, '0')}${blue.toString(16).padStart(2, '0')}`;
// }
//
// function getColor11(percent: number): string {
//     const red = percent < 50 ? 0 : Math.round(255 - (percent - 50) * 5.1);
//     const green = 0;
//     const blue = percent < 50 ? Math.round(percent * 5.1) : Math.round(255 - (percent - 50) * 5.1);
//     return `#${red.toString(16).padStart(2, '0')}${green.toString(16).padStart(2, '0')}${blue.toString(16).padStart(2, '0')}`;
// }
//
// function getColor10(percent: number): string {
//     const red = percent < 50 ? Math.round(percent * 5.1) : Math.round(255 - (percent - 50) * 5.1);
//     const green = percent < 50 ? Math.round(percent * 5.1) : Math.round(255 - (percent - 50) * 5.1);
//     const blue = 0;
//     return `#${red.toString(16).padStart(2, '0')}${green.toString(16).padStart(2, '0')}${blue.toString(16).padStart(2, '0')}`;
// }
//
// function getColor9(percent: number): string {
//     const red = percent < 50 ? Math.round(percent * 5.1) : Math.round(255 - (percent - 50) * 5.1);
//     const green = percent < 50 ? Math.round(percent * 5.1) : Math.round(255 - (percent - 50) * 5.1);
//     const blue = 0;
//     return `#${red.toString(16).padStart(2, '0')}${green.toString(16).padStart(2, '0')}${blue.toString(16).padStart(2, '0')}`;
// }
//
// function getColor8(percent: number): string {
//     const red = percent < 50 ? Math.round(percent * 10.2) : 255;
//     const green = percent < 50 ? Math.round(percent * 2) : Math.round(510 - percent * 2);
//     const blue = 0;
//     return `#${red.toString(16).padStart(2, '0')}${green.toString(16).padStart(2, '0')}${blue.toString(16).padStart(2, '0')}`;
// }
//
// function getColor7(percent: number): string {
//     const red = percent < 50 ? Math.round(percent * 5.1) : 255;
//     const green = percent < 50 ? Math.round(percent * 5.1) : Math.round(510 - percent * 5.1);
//     const blue = 0;
//     return `#${red.toString(16).padStart(2, '0')}${green.toString(16).padStart(2, '0')}${blue.toString(16).padStart(2, '0')}`;
// }

function getColor6(percent: number): string {
    const red = percent < 50 ? Math.round(percent * 5.1) : 240;
    const green = percent <= 51 ? 235 : Math.round(510 - percent * 5.1);
    const blue = 0;
    return `#${red.toString(16).padStart(2, '0')}${green.toString(16).padStart(2, '0')}${blue.toString(16).padStart(2, '0')}`;
}

// function getColor5(percent: number): string {
//     const green = Math.round(220 - percent * 2.2);
//     return `#${green.toString(16).padStart(2, '0')}f0${green.toString(16).padStart(2, '0')}`;
// }

// function getColor4(percent: number): string {
//     const red = percent < 50 ? Math.round(4 + percent * 3.02) : Math.round(251 - (percent - 50) * 3.02);
//     const green = percent < 50 ? Math.round(152 + percent * 1.72) : Math.round(200 - (percent - 50) * 1.72);
//     const blue = percent < 50 ? Math.round(241 + percent * 0.17) : Math.round(184 - (percent - 50) * 0.17);
//     const lighten = Math.round(percent * 0.01 * (255 - parseInt(`0x${red.toString(16)}${green.toString(16)}${blue.toString(16)}`)));
//     return `#${Math.min(Math.max((red + lighten), red), 255).toString(16).padStart(2, '0')}${Math.min(Math.max((green + lighten), green), 255).toString(16).padStart(2, '0')}${Math.min(Math.max((blue + lighten), blue), 255).toString(16).padStart(2, '0')}`;
// }
//
// function getColor3(percent: number): string {
//     const red = percent < 50 ? Math.round(4 + percent * 3.02) : Math.round(251 - (percent - 50) * 3.02);
//     const green = percent < 50 ? Math.round(152 + percent * 1.72) : Math.round(200 - (percent - 50) * 1.72);
//     const blue = percent < 50 ? Math.round(241 + percent * 0.17) : Math.round(184 - (percent - 50) * 0.17);
//     const lighten = Math.round(percent * 0.01 * (255 - parseInt(`0x${red.toString(16)}${green.toString(16)}${blue.toString(16)}`)));
//     return `#${Math.min(Math.max((red + lighten), 0), 255).toString(16).padStart(2, '0')}${Math.min(Math.max((green + lighten), 0), 255).toString(16).padStart(2, '0')}${Math.min(Math.max((blue + lighten), 0), 255).toString(16).padStart(2, '0')}`;
// }
//
// function getColor2(percent: number): string {
//     const red = percent < 50 ? Math.round(4 + percent * 3.02) : Math.round(251 - (percent - 50) * 3.02);
//     const green = percent < 50 ? Math.round(152 + percent * 1.72) : Math.round(200 - (percent - 50) * 1.72);
//     const blue = percent < 50 ? Math.round(241 + percent * 0.17) : Math.round(184 - (percent - 50) * 0.17);
//     return `#${green.toString(16).padStart(2, '0')}${red.toString(16).padStart(2, '0')}${blue.toString(16).padStart(2, '0')}`;
// }
//
// function getColor1(percent: number): string {
//     const red = percent <= 50 ? 255 : Math.round(256 - (percent - 50) * 5.12);
//     const green = percent > 50 ? 255 : Math.round(percent * 5.12);
//     const blue = 0;
//     return `#${green.toString(16).padStart(2, '0')}${red.toString(16).padStart(2, '0')}${blue.toString(16).padStart(2, '0')}`;
// }

const WeekScheduleTable: React.FC<OneWeekScheduleProps> = ({week, onValueFromChild, rowToScrollId, hours, popularTimes}) => {

    return (
        <TableContainer component={Paper} sx={{borderRadius: 3, paddingRight: 1, paddingBottom: 1}}>
            <Table sx={{maxWidth: '100%', padding: '10', margin: '20'}} size="small" aria-label="a dense table">
                <TableBody>
                    {week && week.map((day) => {
                        if (!day) {
                            return null;
                        }
                        if (day.lanes === undefined) {
                            return null;
                        }
                        return [
                            <React.Fragment key={day.name}>
                                <TableRow sx={{'td, th': {border: 0, borderColor: 'lightgray', paddingTop: 2}}}>
                                    <TableCell
                                        sx={{position: "sticky", backgroundColor: '#ffffff', left: 0}}
                                        // style={{position: "sticky", backgroundColor: '#ff0000', left: 0}}
                                        colSpan={20} align="left">
                                        {daysOfWeek[day.name === undefined ? "" : day.name]} - {day.date}
                                    </TableCell>
                                </TableRow>
                                <TableRow sx={{'td, th': {border: 1, borderColor: 'lightgray'}}}>
                                    <TableCell align="center"
                                               style={{
                                                   paddingInline: lanePadding,
                                                   position: "sticky",
                                                   left: 0,
                                                   backgroundColor: '#ffffff'
                                               }}>
                                    </TableCell>
                                    {hours?.map((hour) => (
                                        hour.endsWith("00") &&
                                        <TableCell
                                            key={hour + '-' + day.name}
                                            align="left"
                                            colSpan={4}
                                            style={{
                                                background: hour.startsWith(new Date().getHours().toFixed()) ? '#e0e0e0' : '#FFFFFF',
                                                // backgroundColor: getPopularityBackgroundColor(popularTimes, day.name, hour),
                                                fontSize: "small",
                                                // fontWeight: "bold",
                                                // fontWeight: hour.startsWith(new Date().getHours().toFixed()) ? 'bold' : 'normal',
                                                // borderBottomColor: hour.startsWith(new Date().getHours().toFixed()) ? '#000000' : 'lightgray',
                                                // borderBottomWidth: hour.startsWith(new Date().getHours().toFixed()) ? 'thin' : 'inherit'
                                            }}
                                            // style={{paddingInline: lanePadding}}
                                            // sx={{
                                            //     th: {
                                            //         border: 1, borderColor: 'lightgray',
                                            //         borderRightColor: hour.endsWith("00") ? '#a4a2a2' : '#D3D3D3',
                                            //         borderBottomColor: hour.endsWith("45") ? '#a4a2a2' : '#D3D3D3',
                                            //         background: hour.startsWith(new Date().getHours().toFixed()) ? '#D3D3D3' : '#FFFFFF'
                                            //     },
                                            //     td: {
                                            //         border: 1,
                                            //         borderColor: 'lightgray',
                                            //         borderTopColor: hour.endsWith("00") ? '#a4a2a2' : '#D3D3D3',
                                            //         borderBottomColor: hour.endsWith("45") ? '#a4a2a2' : '#D3D3D3',
                                            //     }
                                            // }}
                                        >
                                            {hour.endsWith("00") ? hour : ""}
                                        </TableCell>
                                    ))}
                                </TableRow>
                                <TableRow sx={{'td, th': {border: 1, borderColor: 'lightgray', height: 0}}}>
                                    <TableCell align="center"
                                               style={{
                                                   paddingInline: lanePadding,
                                                   position: "sticky",
                                                   left: 0,
                                                   backgroundColor: '#ffffff'
                                               }}>
                                        {/*<Tooltip open={open} onClose={true} title="More info">*/}
                                        {/*<Tooltip title="More info" open={true}>*/}
                                        {/*<IconButton size={"small"} sx={{padding: 0}}>*/}
                                        {/*    <InfoOutlinedIcon sx={{ padding: 0}} />*/}
                                        {/*</IconButton>*/}
                                        {/*</Tooltip>*/}
                                    </TableCell>
                                    {hours?.map((hour) => (
                                        hour.endsWith("00") &&
                                        <Tooltip
                                            title={"Vytíženost podle Google Maps: " + getPopularityPercent(popularTimes, day.name, hour) + " %"}
                                            key={"tooltip-" + hour + '-' + day.name}
                                            arrow
                                        >
                                            <TableCell
                                                key={hour + '-' + day.name}
                                                align="right"
                                                colSpan={4}
                                                style={{
                                                    background: hour.startsWith(new Date().getHours().toFixed()) ? '#e0e0e0' : '#FFFFFF',
                                                    // backgroundColor: getPopularityBackgroundColor(popularTimes, day.name, hour),
                                                    borderBottomColor: getPopularityBackgroundColor(popularTimes, day.name, hour),
                                                    borderBottomWidth: "thick",
                                                    fontSize: "xx-small",
                                                    color: "#a4a2a2",
                                                    paddingTop: 0,
                                                    paddingBottom: 0,
                                                    paddingRight: 2,
                                                    // fontWeight: "bold",
                                                    fontWeight: hour.startsWith(new Date().getHours().toFixed()) ? 'bold' : 'normal',
                                                    // borderBottomWidth: hour.startsWith(new Date().getHours().toFixed()) ? 'thin' : 'inherit'
                                                }}
                                            >
                                                {hour.endsWith("00") ? getPopularityPercent(popularTimes, day.name, hour) + " %" : ""}
                                            </TableCell>
                                        </Tooltip>
                                    ))}
                                </TableRow>
                            </React.Fragment>,

                            <React.Fragment key={day.name + 'swimming-direction'}>
                                {day.swimmingDirections != null && day.swimmingDirections.length !== 0 && <TableRow
                                    key={day.name + '-swimming-direction'}
                                    sx={{'td, th': {border: 1, borderColor: 'lightgray'}}}
                                >
                                    <TableCell
                                        component="th"
                                        scope="row"
                                        align="center"
                                        style={{
                                            paddingInline: laneNumberPadding,
                                            position: "sticky",
                                            left: 0,
                                            backgroundColor: '#ffffff',
                                            paddingTop: 1,
                                            paddingBottom: 1,
                                            fontSize: "x-small"
                                        }}
                                    >

                                    </TableCell>
                                    {hours?.map((hour, hourIndex) => (
                                        <Tooltip
                                            title={day.swimmingDirections != null && day.swimmingDirections[hourIndex] === "WIDTH" && "Plavání na šířku bazénu"}
                                            key={"tooltip-" + hour + '-' + day.name}
                                            arrow
                                        >
                                        <TableCell align="center"
                                            style={{
                                                // paddingLeft: 5,
                                                // left: 0,
                                                backgroundColor: '#ffffff',
                                                padding: 0,
                                                fontSize: "small",
                                            }}
                                        >
                                            {day.swimmingDirections != null && day.swimmingDirections[hourIndex] === "WIDTH" &&
                                                "↕"
                                            }
                                        </TableCell>
                                        </Tooltip>
                                        ))
                                    }


                                </TableRow>}

                            </React.Fragment>,

                            ...day.lanes.map((lane: Lane, rowIndex) => (
                                <TableRow
                                    key={rowIndex + '-' + day.name}
                                    sx={{'td, th': {border: 1, borderColor: 'lightgray'}}}
                                >
                                    <TableCell
                                        component="th"
                                        scope="row"
                                        align="center"
                                        style={{
                                            paddingInline: laneNumberPadding,
                                            position: "sticky",
                                            left: 0,
                                            backgroundColor: '#ffffff',
                                            paddingTop: 1,
                                            paddingBottom: 1,
                                            fontSize: "x-small"
                                        }}
                                    >
                                        {lane.number}
                                    </TableCell>

                                    {lane.slots?.map((slot, slotIndex) => (
                                        <TableCell
                                            key={rowIndex + '-' + slotIndex}
                                            align="center"
                                            style={{
                                                padding: 0,
                                                margin: 0,
                                                background: slot === "O" ? occupiedColor : (slot === "C" ? closeColor : accessibleColor),
                                                color: slot === "O" ? occupiedColor : (slot === "C" ? closeColor : accessibleColor),
                                                fontSize: "xx-small",
                                            }}
                                            // TODO continue
                                            // sx={{
                                            //     paddingInline: lanePadding + 5,
                                            //     background: slot === "O" ? occupiedColor : (slot === "C" ? closeColor : accessibleColor),
                                            //     color: slot === "O" ? "gray" : (slot === "C" ? closeColor : accessibleColor),
                                            //     fontSize: 9,
                                            //     th: {
                                            //         border: 1, borderColor: 'lightgray',
                                            //         borderRightColor: (slotIndex + 1) % 4 === 0 ? '#a4a2a2' : '#D3D3D3',
                                            //         borderBottomColor: (slotIndex + 1) % 4 === 0 ? '#a4a2a2' : '#D3D3D3',
                                            //         // background: hour.startsWith(new Date().getHours().toFixed()) ? '#D3D3D3' : '#FFFFFF'
                                            //     },
                                            //     td: {
                                            //         border: 1,
                                            //         borderColor: 'red',
                                            //         borderTopColor: (slotIndex + 1) % 4 === 0 ? '#a4a2a2' : '#D3D3D3',
                                            //         borderBottomColor: (slotIndex + 1) % 4 === 0 ? '#a4a2a2' : '#D3D3D3',
                                            //     }
                                            // }}

                                        >
                                            {slot === "A" ? "a" : "×"}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))

                        ];
                    }).flat()}
                </TableBody>

                <TableFooter>
                    <TableRow sx={{borderStyle: "none"}}>
                        <TableCell colSpan={40} sx={{borderStyle: "none"}}>
                            <Legend/>
                        </TableCell>
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainer>
    );
}

export default WeekScheduleTable